<template>
  <div class="order">
    <Title @queryOrder="GetOrderList" />
    <div class="List">
      <div class="Tabs">
        <div class="Tabs-item">全部</div>
      </div>
      <List :List="OrderList" @ToPay="ToPay" @GetDetails="GetDetails" @refunds="showRefundsDialog"
            @changePrice="changePrice"
            @GetWork="GetWork" @DelOrder="DelOrder" @GetEvaluate="GetEvaluate" />
      <el-empty v-show="showEmpty" :image="NoList"></el-empty>
      <Pagination :total="total" :size="queryParam.pageSize" :page="queryParam.pageNo" @handlePage="PageChange"
                  v-if="total > queryParam.pageSize" />
      <Work ref="Work" :title="Worktitle" :WorkDesc="WorkDesc" @handleWork="handleWork" />
      <!-- 评价 -->
      <Evaluate ref="Evaluate" @List="GetOrderList" />
      <refunds ref="refunds" :title="refunds.title" :confirm="refunds.confirm" @confirm="confirmRefunds" />
    </div>
  </div>
</template>
<script>
import Title from "@/components/OrderTab/Title.vue";
import List from "@/components/OrderTab/Order.vue";
// import Open from "@/views/Order/Open.vue"
import Work from "@/views/Order/Work.vue";
import Evaluate from "@/views/Order/Evaluate.vue";
import Pagination from "@/components/Pagination/index.vue";
import refunds from "@/views/Order/refunds.vue";
import moment from "moment";
import { ORDER_STATE } from "@/constants/order";

export default {
  data() {
    return {
      OrderList: [],
      OrderId: null,
      Worktitle: null,
      WorkDesc: null,
      NoList: require("@/assets/imgs/no_hall.png"),
      showEmpty: false,
      
      total: 0,
      
      queryParam: {
        orderUserId:    this.$store.getters.getUser.id,
        pageNo:         1,
        pageSize:       10,
      },
      refunds: {
        title: '退款',
        confirm: '确定'
      }
    };
  },
  components: {
    Pagination,
    Title,
    List,
    // Open,
    Work,
    refunds,
    Evaluate,
  },
  created() {
    this.GetOrderList();
  },
  
  methods: {
    // 我的订单
    GetOrderList(param) {
      let url = `/app-api/hiring/service-order/page`;
      
      if (param && param.state) {
        this.queryParam.orderState = param.state;
      } else {
        delete this.queryParam.orderState
      }
      if (param && param.date && param.date.length > 1) {
        this.queryParam.createTime = [moment(param.date[0]).format('YYYY-MM-DD HH:mm:ss'), moment(param.date[1]).format('YYYY-MM-DD HH:mm:ss')].join(',');
      } else {
        delete this.queryParam.createTime
      }
      // console.log(this.queryParam)
      
      this.$axios
          .get(url, { params: this.queryParam })
          .then((res) => {
            if (res.data.code === 0) {
              this.OrderList = res.data.data.list;
              this.total = res.data.data.total
              this.showEmpty = !this.OrderList;
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    PageChange(val) {
      this.queryParam.pageNo = val;
      this.GetOrderList(this.queryParam);
    },
    // 支付订单
    ToPay(item) {
      // 现阶段 弹框
      this.$router.push({
        path: "/Order/Payment",
        query: { id: item.id },
      });
    },
    // 取消服务订单
    cancelOrder(item) {
      this.$model.loading();
      let url = "/app-api/hiring/service-order/cancel";
      this.$axios
          .post(url, { id: item.id })
          .then((res) => {
            if (res.data.code === 0) {
              this.$notify.success("取消成功!");
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    // 去详情页面
    GetDetails(item) {
      this.$router.push({
        path: "/Order/Detail",
        query: { id: item.id },
      });
    },
    changePrice(item) {
      this.$prompt('请输入价格', '修改价格', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^(\d+)((?:\.\d+)?)$/,
        inputErrorMessage: '请输入大于零的有效数字',
        closeOnClickModal: false
      }).then(({ value }) => {
        this.doChangePrice(item, value)
      }).catch(() => {
      });
      
    },
    doChangePrice(item, value) {
      let url = `/app-api/hiring/service-order/update-price`;
      this.$axios
          .post(url, {
            id: item.id,
            price: value
          })
          .then((res) => {
            if (res.data.code === 0) {
              this.$notify.success("改价成功！");
              this.GetOrderList();
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    // 退款
    showRefundsDialog(item) {
      if (item.orderState === ORDER_STATE.PAID.value) {
        this.refunds.title = "是否退款";
        this.refunds.confirm = "确认退款";
      }
      this.$refs.refunds.Open = true;
      this.OrderId = item;
    },
    confirmRefunds(reason) {
      let url = `/app-api/hiring/service-order/refund`;
      this.$axios
          .post(url, {
            orderNo: this.OrderId.orderNo,
            reason: reason
          })
          .then((res) => {
            if (res.data.code === 0) {
              this.$notify.success("退款申请提交成功！");
              this.$refs.refunds.Open = false;
              this.GetOrderList();
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    // 开始工作 start
    GetWork(item) {
      // 弹框提示
      if (item.orderState === ORDER_STATE.WAITING_CHECK.value) {
        this.Worktitle = "是否验收工作";
        this.WorkDesc = "确认验收";
      }
      this.$refs.Work.Open = true;
      this.OrderId = item;
    },
    handleWork() {
      if (this.OrderId.orderState === ORDER_STATE.WAITING_CHECK.value) {
        this.PostAccept();
      } else if (this.OrderId.orderState === ORDER_STATE.CANCELED.value) {
        this.DelOrderList();
      }
    },
    // 验收
    PostAccept() {
      let url = "/app-api/hiring/service-order/check-accept/";
      this.$axios
          .post(url, { id: this.OrderId.id })
          .then((res) => {
            if (res.data.code === 0) {
              this.$notify.success("验收成功！");
              this.$refs.Work.Open = false;
              this.GetOrderList();
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    // 去评价
    GetEvaluate(item) {
      this.OrderId = item;
      this.$refs.Evaluate.open = true;
      item.avter = this.service_label(item.serviceImg)[0];
      this.$refs.Evaluate.order = item;
    },
    // 删除订单
    DelOrder(item) {
      this.Worktitle = "是否删除订单";
      this.WorkDesc = "删除订单";
      this.$refs.Work.Open = true;
      this.OrderId = item;
    },
    DelOrderList() {
      let url = "/app-api/hiring/service-order/delete";
      this.$axios
          .delete(url, { params: { id: this.OrderId.id } })
          .then((res) => {
            if (res.data.code === 0) {
              this.$notify.success("删除成功！");
              this.$refs.Work.Open = false;
              this.GetOrderList();
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
  },
};
</script>
<style lang="less" scoped>
.List {
  width: 100%;
  
  .Tabs {
    display: flex;
    margin-top: 40px;
    
    .Tabs-item {
      font-weight: 400;
      color: #000000;
      line-height: 23px;
      font-size: 20px;
      position: relative;
      
      &::after {
        content: "";
        position: absolute;
        bottom: -10px;
        width: 80%;
        height: 2px;
        background: #000000;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
</style>
