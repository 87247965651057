<template>
  <!-- 我的订单 -->
  <div class="Order">
    <!-- 头部 -->
    <div class="Head">
      <div class="title">标题</div>
      <div class="fuwushang">服务商</div>
      <div class="day">下单时间</div>
      <div class="remain">剩余时间</div>
      <div class="price">金额</div>
      <div class="type">状态</div>
      <div class="operate">操作</div>
    </div>
    <!-- 列表 -->
    <div class="List">
      <div class="item" v-for="item in renderList" :key="item.id" @click="getDetails(item)">
        <div class="title">
          <img :src="service_label(item.serviceImg)[0]" alt />
          <div class="content">{{ item.serviceTitle }}</div>
        </div>
        <div class="fuwushang" @click.stop="clickChat(item)">
          <img :src="item.user.avatarImg" alt />
          <div class="fuwushangName">{{ item.user.nickName || item.provider.providerName }}</div>
        </div>
        <div class="day">{{ formatDate(item.createTime) }}</div>
        <div class="remain" v-if="item.orderState === ORDER_STATE.WORKING.value">
          <countdown-timer :end-time="getRemainTime(item)" />
        </div>
        <div class="remain" v-else>--</div>
        <div class="price">
          ￥{{ item.orderAmount }}
        </div>
        <div class="type">
          <!-- 1 待支付 -->
          <div class="type warn" v-if="item.orderState === ORDER_STATE.WAITING_PAY.value">
            <div>待支付</div>
            <div class="countdown">
              <countdown-timer :end-time="residueTime(item.createTime)" />
            </div>
          </div>
          <div class="type" v-else :class="item.stateClass">
            {{ item.state }}
          </div>
        </div>
        
        <div class="operate">
          <!-- 去支付 -->
          <el-button class="btn-operate type-cancel" size="mini"
                     v-if="item.orderState === ORDER_STATE.WAITING_PAY.value && residueTime(item.createTime) > 0"
                     @click.stop="toPay(item)"
                     >
            支付
          </el-button>
          <!--<el-button class="btn-operate type-cancel" size="mini"-->
          <!--           v-if="item.orderState === ORDER_STATE.WAITING_PAY.value && residueTime(item.createTime) > 0"-->
          <!--           @click.stop="clickChangePrice(item)"-->
          <!--&gt;-->
          <!--  改价-->
          <!--</el-button>-->
          <el-button class="btn-operate type-cancel" size="mini"
                     v-if="item.orderState === ORDER_STATE.PAID.value"
                     @click.stop="clickRefunds(item)"
          >
            退款
          </el-button>
          <el-button class="btn-operate type-cancel" size="mini"
                     v-if="item.orderState === ORDER_STATE.CANCELED.value && residueTime(item.createTime) <= 0"
                     @click.stop="clickDelete(item)">
            删除
          </el-button>
          <!-- 待验收 -->
          <el-button class="btn-operate type-success" size="mini"
                     v-if="item.orderState === ORDER_STATE.WAITING_CHECK.value"
                     @click.stop="getAccept(item)">
            验收
          </el-button>
          <el-button class="btn-operate type-cancel" size="mini"
                     v-if="item.orderState === ORDER_STATE.WAITING_CHECK.value"
                     @click.stop="getAppeal(item)">
            申诉
          </el-button>

          <!-- 已完成 -->
          <el-button class="btn-operate type-cancel" size="mini"
                     v-if="item.orderState === ORDER_STATE.CHECK_PASS.value"
                     @click.stop="getEvaluate(item)">
            评价
          </el-button>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CountdownTimer from "@/components/FlipDown/countdownTimer.vue";
import { mapActions } from "vuex";
import { ORDER_STATE } from "@/constants/order";

export default {
  props: {
    List: {
      type: Array,
      default: () => {
        return [];
      },
    },
    State: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    CountdownTimer,
  },
  computed: {
    ORDER_STATE() {
      return ORDER_STATE
    },
    renderList() {
      let list = this.List || []
      
      list.forEach(item => {
        let key = Object.keys(this.ORDER_STATE).find(key => this.ORDER_STATE[key].value === item.orderState)
        if (key) {
          item.state = this.ORDER_STATE[key].label
          item.stateClass = this.ORDER_STATE[key].cssClass
          
          let remainTime = this.getRemainTime(item)
          if (remainTime < 0) {
            // item.state = this.ORDER_STATE.EXPIRED.label
            // item.orderState = this.ORDER_STATE.EXPIRED.value
            // item.stateClass = this.ORDER_STATE.EXPIRED.cssClass
          } else {
            item.remainTime = remainTime
          }
        }
      })
      return list;
    }
  },
  methods: {
    ...mapActions(['showChat']),
    getRemainTime(item) {
      // 获取当前时间段
      let now = new Date().getTime();
      let endTime = item.updateTime;
      
      // 下的是小时单
      if (item.serviceAmountType === "hour") {
        endTime += item.orderNum * 1 * 60 * 60 * 1000;
      }
      // 下的是 1天 单
      else if (item.serviceAmountType === "day") {
        endTime += item.orderNum * 24 * 60 * 60 * 1000;
      }
      // 下的是 1周 单
      else if (item.serviceAmountType === "week") {
        endTime += item.orderNum * 7 * 24 * 60 * 60 * 1000;
      }
      // 下的是 半个月的单子
      else {
        endTime += item.orderNum * 7 * 24 * 60 * 60 * 1000;
      }
      // 说明逾期了
      if (now > endTime) {
        return -1000;
      }
      return endTime;
    },
    // 计算订单剩余支付时间
    residueTime(time) {
      let now = new Date().getTime();
      let endTime = time + 1000 * 60 * 60 * 2;
      return now > endTime ? 0 : endTime;
    },
    // 去支付
    toPay(item) {
      this.$emit("ToPay", item);
    },
    // 订单详情 的点击事件
    getDetails(item) {
      this.$emit("GetDetails", item);
    },
    // 取消订单
    cancelOrder(item) {
      this.$emit("cancelOrder", item);
    },
    clickChangePrice(item) {
      this.$emit("changePrice", item);
    },
    clickRefunds(item) {
      this.$emit("refunds", item);
    },
    // 删除订单
    clickDelete(item) {
      this.$emit("DelOrder", item);
    },
    // 开始工作
    getWork(item) {
      this.$emit("GetWork", item);
    },
    // 验收
    getAccept(item) {
      this.getWork(item);
    },
    // 评价
    getEvaluate(item) {
      this.$emit("GetEvaluate", item);
    },
    // 申诉
    // 跳转到申诉界面
    getAppeal(item) {
      this.$router.push({
        name: "Appeal",
        params: { id: item.id },
      });
    },
    clickChat(item) {
      if (item.user.userPhone === this.$store.getters.getUser.userPhone) {
        this.$notify.info('您不能向自己在线咨询！')
        return
      }
      this.showChat({
        show: true,
        phone: item.user.userPhone ,
        goods: {
          id: '',
          name: '',
          desc: '',
          img: ''
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.Order {
  margin-top: 39px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 4px 4px 1px rgba(204, 226, 237, 0.5);
}

.title {
  flex: 1
}

.phone {
  width: 140px;
}

.fuwushang {
  width: 140px;
}

.day {
  width: 150px;
}

.remain {
  width: 150px;
}

.price {
  width: 100px;
}

.type {
  width: 100px;
}

.operate {
  width: 140px;
}

.Head {
  height: 45px;
  background: #f1f1f1;
  display: flex;
  align-items: center;
  padding-left: 28px;
  padding-right: 5px;
  
  & > div {
    text-align: left;
    color: #000000;
    line-height: 23px;
    font-size: 18px;
    
    & + div {
      margin-left: 5px;
    }
  }
}

.List {
  
  .item {
    height: 74px;
    display: flex;
    align-items: center;
    padding-left: 28px;
    padding-right: 5px;
    background: #ffffff;
    color: #000000;
    cursor: pointer;
    line-height: 19px;
    font-size: 16px;
    
    &:hover {
      background: #ecf5ff;
    }
    
    > div + div {
      margin-left: 5px;
    }
    
    .title {
      display: flex;
      align-items: center;
      
      img {
        width: 57px;
        height: 42px;
      }
      
      .content {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        margin-left: 10px;
      }
    }
    
    .phone {
      text-align: center;
    }
    
    .fuwushang {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      
      img {
        width: 36px;
        height: 36px;
        border-radius: 50%;
      }
      
      .fuwushangName {
        color: #333333;
        margin-left: 8px;
      }
    }
    
    .day {
      color: #555555;
      font-size: 14px;
    }
    
    .remain {
      color: #333333;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    
    .price {
      color: #fc3737;
    }
    
    .type {
      font-size: 14px;
      
      &.info {
        color: #999999;
      }
      
      &.success {
        color: #209F85;
      }
      
      &.warn {
        color: #ee914e;
      }
      
      &.danger {
        color: #FF3D3D;
      }
      
      .countdown {
        font-size: 12px;
      }
    }
    
    .operate {
      display: flex;
      
      .el-button + .el-button {
        margin-left: 10px;
      }
    }
  }
}

</style>
